import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import check_email_availability_en from "./locales/en/auth/check_email_availability.json";
import forgot_password_en from "./locales/en/auth/forgot_password.json";
import google_login_en from "./locales/en/auth/google_login.json";
import login_en from "./locales/en/auth/login.json";
import onboarding_en from "./locales/en/auth/onboarding.json";
import register_en from "./locales/en/auth/register.json";
import reset_password_en from "./locales/en/auth/reset_password.json";
import common_en from "./locales/en/common.json";
import error_en from "./locales/en/error.json";
import fields_en from "./locales/en/fields.json";
import receipts_en from "./locales/en/receipts.json";
import scratch_games_en from "./locales/en/scratch_games.json";
import check_email_availability_es from "./locales/es/auth/check_email_availability.json";
import forgot_password_es from "./locales/es/auth/forgot_password.json";
import google_login_es from "./locales/es/auth/google_login.json";
import login_es from "./locales/es/auth/login.json";
import onboarding_es from "./locales/es/auth/onboarding.json";
import register_es from "./locales/es/auth/register.json";
import reset_password_es from "./locales/es/auth/reset_password.json";
import common_es from "./locales/es/common.json";
import error_es from "./locales/es/error.json";
import fields_es from "./locales/es/fields.json";
import receipts_es from "./locales/es/receipts.json";
import scratch_games_es from "./locales/es/scratch_games.json";
import check_email_availability_fr from "./locales/fr/auth/check_email_availability.json";
import forgot_password_fr from "./locales/fr/auth/forgot_password.json";
import google_login_fr from "./locales/fr/auth/google_login.json";
import login_fr from "./locales/fr/auth/login.json";
import onboarding_fr from "./locales/fr/auth/onboarding.json";
import register_fr from "./locales/fr/auth/register.json";
import reset_password_fr from "./locales/fr/auth/reset_password.json";
import common_fr from "./locales/fr/common.json";
import error_fr from "./locales/fr/error.json";
import fields_fr from "./locales/fr/fields.json";
import receipts_fr from "./locales/fr/receipts.json";
import scratch_games_fr from "./locales/fr/scratch_games.json";

const resources = {
	es: {
		common: common_es,
		fields: fields_es,
		receipts: receipts_es,
		error: error_es,
		onboarding: onboarding_es,
		forgot_password: forgot_password_es,
		reset_password: reset_password_es,
		login: login_es,
		register: register_es,
		google_login: google_login_es,
		check_email_availability: check_email_availability_es,
		scratch_games: scratch_games_es,
	},
	en: {
		common: common_en,
		fields: fields_en,
		receipts: receipts_en,
		error: error_en,
		onboarding: onboarding_en,
		forgot_password: forgot_password_en,
		reset_password: reset_password_en,
		login: login_en,
		register: register_en,
		google_login: google_login_en,
		check_email_availability: check_email_availability_en,
		scratch_games: scratch_games_en,
	},
	fr: {
		common: common_fr,
		fields: fields_fr,
		receipts: receipts_fr,
		error: error_fr,
		onboarding: onboarding_fr,
		forgot_password: forgot_password_fr,
		reset_password: reset_password_fr,
		login: login_fr,
		register: register_fr,
		google_login: google_login_fr,
		check_email_availability: check_email_availability_fr,
		scratch_games: scratch_games_fr,
	},
};

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		ns: Object.keys(resources.fr),
		defaultNS: "common",
		fallbackLng: "fr",

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
