import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { shadows } from "@noticia/ui";

const WidgetContainer = styled(Box)(({ theme }) => ({
	boxShadow: shadows.lg,
	borderRadius: theme.spacing(2),
	overflow: "hidden",
}));

export default WidgetContainer;
