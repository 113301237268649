import type { ReactElement } from "react";
import Box from "@mui/material/Box";

import { Typography, type TypographyVariant } from "@noticia/ui";

type NavigationChipSize = "small" | "medium";

const sizeToHeight: Record<NavigationChipSize, number> = {
	small: 19,
	medium: 32,
};

const sizeToLabelVariant: Record<NavigationChipSize, TypographyVariant> = {
	small: "body5",
	medium: "body3",
};

export type ChipProps = {
	label: string;
	size?: NavigationChipSize;
	icon: ReactElement;
	selected?: boolean;
	onClick?: () => void;
};

const Chip = (props: ChipProps) => {
	const { label, size = "medium", icon, selected = false, onClick } = props;

	return (
		<Box
			display="flex"
			alignItems="center"
			height={sizeToHeight[size]}
			aria-label={label}
			sx={(theme) => ({
				width: "fit-content",
				cursor: "pointer",
				pr: size === "small" ? 1 : 1.5,
				color: selected
					? theme.palette.neutral[0]
					: theme.palette.navyBlue[900],
				borderRadius: theme.spacing(2),
				backgroundColor: selected
					? theme.palette.navyBlue[900]
					: theme.palette.neutral[50],
			})}
			onClick={onClick}
		>
			{icon}
			<Typography
				variant={sizeToLabelVariant[size]}
				fontWeight="medium"
				component="span"
			>
				{label}
			</Typography>
		</Box>
	);
};

export default Chip;
