import { lazy, PropsWithChildren, Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { Route, Switch, useLocation } from "wouter";

import { Alert, Button, Typography } from "@noticia/ui";

import BackToReceiptsNavigationChip from "~/components/atoms/NavigationChip/BackToReceiptsNavigationChip";
import LoadingLayout from "~/components/layouts/LoadingLayout";
import ReceiptSkeleton from "~/components/molecules/Receipts/ReceiptSkeleton";
import * as constants from "~/constants";
import { APP_ROUTES } from "~/constants";
import useUpgradeJwtFromV1 from "~/hooks/auth/useUpgradeJWTFromV1";
import { ApiError } from "~/services/api";

import theme from "../theme";

/** @see https://mui.com/x/introduction/licensing/ */
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE_KEY);

const AuthenticatePage = lazy(() => import("~/pages/Authenticate"));
const Authenticate = () => (
	<Suspense fallback={<LoadingLayout />}>
		<AuthenticatePage />
	</Suspense>
);

const ForgotPasswordPage = lazy(() => import("~/pages/ForgotPassword"));
const ForgotPassword = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ForgotPasswordPage />
	</Suspense>
);

const ResetPasswordPage = lazy(() => import("~/pages/ResetPassword"));
const ResetPassword = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ResetPasswordPage />
	</Suspense>
);

const ExternalLoginCallbackPage = lazy(
	() => import("~/pages/ExternalLoginCallback"),
);
const ExternalLoginCallback = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ExternalLoginCallbackPage />
	</Suspense>
);

const OnboardingPage = lazy(() => import("~/pages/Onboarding"));
const Onboarding = () => (
	<Suspense fallback={<LoadingLayout />}>
		<OnboardingPage />
	</Suspense>
);

const HomePage = lazy(() => import("~/pages/Home"));
const Home = () => (
	<Suspense fallback={<LoadingLayout />}>
		<HomePage />
	</Suspense>
);

const UserReceiptPageLoading = () => (
	<LoadingLayout>
		<BackToReceiptsNavigationChip
			sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
		/>
		<ReceiptSkeleton />
	</LoadingLayout>
);
const UserReceiptPage = lazy(() => import("~/pages/UserReceipt"));
const UserReceipt = () => (
	<Suspense fallback={<UserReceiptPageLoading />}>
		<UserReceiptPage />
	</Suspense>
);

const ClaimReceiptPage = lazy(() => import("~/pages/ClaimReceipt"));
const ClaimReceipt = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ClaimReceiptPage />
	</Suspense>
);

const ClaimTillLastReceiptPage = lazy(
	() => import("~/pages/ClaimTillLastReceipt"),
);
const ClaimTillLastReceipt = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ClaimTillLastReceiptPage />
	</Suspense>
);

const PrivacyPolicyPage = lazy(() => import("~/pages/PrivacyPolicy"));
const PrivacyPolicy = () => (
	<Suspense fallback={<LoadingLayout />}>
		<PrivacyPolicyPage />
	</Suspense>
);

const ScratchingGameRulesPage = lazy(() => import("~/pages/ScratchGameRules"));
const ScratchingGameRules = () => (
	<Suspense fallback={<LoadingLayout />}>
		<ScratchingGameRulesPage />
	</Suspense>
);

const NotFoundPage = lazy(() => import("~/pages/NotFound"));
const NotFound = () => (
	<Suspense fallback={<LoadingLayout />}>
		<NotFoundPage />
	</Suspense>
);

const MigrationWrapper = ({ children }: PropsWithChildren) => {
	const { t } = useTranslation("error");

	const { status, fetchStatus } = useUpgradeJwtFromV1();

	if (fetchStatus === "fetching") {
		return null;
	}

	if (status === "error") {
		return (
			<Box p={4} display="flex" justifyContent="center">
				<Alert severity="error">
					<Typography variant="body3" fontWeight="regular">
						{t("migration_failed.text")}
					</Typography>
					<Button
						variant="primary"
						color="dark_blue"
						rounded
						sx={{ mt: 2, mx: "auto" }}
						onClick={() => window.location.reload()}
					>
						{t("migration_failed.button_text")}
					</Button>
				</Alert>
			</Box>
		);
	}

	return children;
};

const App = () => {
	const [, setLocation] = useLocation();

	const { current: queryClient } = useRef<QueryClient>(
		new QueryClient({
			/**
			 * @tanstack/react-query v5 doesn't provide onError callback anymore
			 * @see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#a-bad-api
			 */
			queryCache: new QueryCache({
				onError: (error: Error) => {
					if (error instanceof ApiError && error.status === 401) {
						localStorage.removeItem(constants.localStorage.token);
						setLocation(APP_ROUTES.AUTH.AUTHENTICATE);
					}
				},
			}),
			defaultOptions: {
				queries: {
					retry: 0,
					staleTime: 60 * 1000,
					gcTime: 1000 * 60 * 60 * 24,
				},
				mutations: {
					retry: import.meta.env.PROD ? 2 : false,
					onError: (error: Error) => {
						if (error instanceof ApiError && error.status === 401) {
							localStorage.removeItem(constants.localStorage.token);
							setLocation(APP_ROUTES.AUTH.AUTHENTICATE);
						}
					},
				},
			},
		}),
	);

	return (
		<ThemeProvider theme={theme}>
			{/** @see https://mui.com/material-ui/react-css-baseline/ */}
			<CssBaseline />

			<QueryClientProvider client={queryClient}>
				<MigrationWrapper>
					<Switch>
						<Route
							path={APP_ROUTES.AUTH.AUTHENTICATE}
							component={Authenticate}
						/>
						<Route
							path={APP_ROUTES.AUTH.FORGOT_PASSWORD}
							component={ForgotPassword}
						/>
						<Route
							path={APP_ROUTES.AUTH.RESET_PASSWORD}
							component={ResetPassword}
						/>
						<Route
							path={APP_ROUTES.AUTH.EXTERNAL_LOGIN_CALLBACK}
							component={ExternalLoginCallback}
						/>
						<Route path={APP_ROUTES.ONBOARDING} component={Onboarding} />

						<Route path={APP_ROUTES.RECEIPTS.LIST} component={Home} />
						<Route
							path={APP_ROUTES.RECEIPTS.USER_RECEIPT(":receiptId")}
							component={UserReceipt}
						/>
						<Route
							path={APP_ROUTES.RECEIPTS.CLAIM_RECEIPT(":receiptId")}
							component={ClaimReceipt}
						/>
						<Route
							path={APP_ROUTES.TILLS.CLAIM_LAST_RECEIPT(":tillId")}
							component={ClaimTillLastReceipt}
						/>
						{/** @deprecated: Use APP_ROUTES.TILLS.CLAIM_LAST_RECEIPT */}
						<Route
							path={APP_ROUTES.TILLS.LEGACY_CLAIM_LAST_RECEIPT(":tillId")}
							component={ClaimTillLastReceipt}
						/>

						<Route path={APP_ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />

						<Route
							path={APP_ROUTES.GAMES.SCRATCHING_GAME_RULES}
							component={ScratchingGameRules}
						/>

						<Route path="*" component={NotFound} />
					</Switch>
				</MigrationWrapper>
			</QueryClientProvider>
		</ThemeProvider>
	);
};

export default App;
