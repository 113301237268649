import type { PropsWithChildren } from "react";
import { Trans } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MUILink from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "wouter";

import { shadows, Typography } from "@noticia/ui";

import Footer from "~/components/layouts/Footer";
import ProfileButton from "~/components/molecules/ProfileButton";
import { APP_ROUTES } from "~/constants";
import useUser from "~/hooks/auth/useUser";

type Props = {
	maxWidth?: "xs" | "sm" | "md";
	disableDataFetching?: boolean;
};

const Layout = (props: PropsWithChildren<Props>) => {
	const { maxWidth = "sm", disableDataFetching = false, children } = props;

	const { isLoading, isLogged, logout } = useUser({
		enabled: !disableDataFetching,
	});

	const displayIntroductionBanner = !isLoading && !isLogged;

	return (
		<Box
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			height="100%"
		>
			<AppBar
				sx={(theme) => ({
					backgroundColor: theme.palette.neutral[0],
					boxShadow: shadows.lg,
				})}
			>
				<Toolbar
					variant="dense"
					sx={{ alignItems: "center", justifyContent: "space-between" }}
				>
					<Link
						to={APP_ROUTES.RECEIPTS.LIST}
						style={{ position: "relative", display: "flex" }}
						aria-label="Logo Noticia"
					>
						<img
							src="/logoNoticiaCropped.png"
							width={100}
							height={30}
							alt="Logo Noticia"
						/>
					</Link>
					<Box display="flex" alignItems="center">
						<Box ml={2} mr={1}>
							<ProfileButton
								isLoading={isLoading}
								isLogged={isLogged}
								onLogout={logout}
							/>
						</Box>
					</Box>
				</Toolbar>
				{displayIntroductionBanner && (
					<Box
						py={1}
						px={2}
						sx={(theme) => ({
							backgroundColor: theme.palette.navyBlue[900],
						})}
						role="banner"
					>
						<Typography
							variant="body5"
							fontWeight="medium"
							display="flex"
							alignItems="center"
							sx={(theme) => ({ color: theme.palette.neutral[0] })}
						>
							<Trans
								ns="receipts"
								i18nKey="receipts_info_modal.receipts_info_label"
								components={{
									login_link: (
										<MUILink
											to={APP_ROUTES.AUTH.AUTHENTICATE}
											component={Link}
											color="neutral.0"
											sx={{ mr: 0.5 }}
										/>
									),
								}}
							/>
						</Typography>
					</Box>
				)}
			</AppBar>

			<Box flexGrow={1} mt={displayIntroductionBanner ? 16 : 11.5} width="100%">
				<Container
					maxWidth={maxWidth}
					sx={{ display: "flex", flexDirection: "column", height: "100%" }}
				>
					{children}
				</Container>
			</Box>
			<Footer />
		</Box>
	);
};

export default Layout;
