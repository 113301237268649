import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import HelpIcon from "@mui/icons-material/HelpOutlineRounded";
import Box from "@mui/material/Box";

import { Button } from "@noticia/ui";

import ReceiptInfoDialog from "~/components/organisms/ReceiptInfo/ReceiptInfoDialog";
import { RECEIPT_IDS } from "~/constants";

const ReceiptInfoButton = () => {
	const { t } = useTranslation("receipts");

	const [isBannerOpen, toggleBanner] = useToggle(false);

	return (
		<Box
			display="flex"
			justifyContent="flex-end"
			px={2}
			id={RECEIPT_IDS.INFO_BUTTON}
		>
			<Button
				variant="secondary"
				color="grey"
				size="small"
				rounded
				endIcon={<HelpIcon sx={{ height: 14, width: 14 }} />}
				sx={(theme) => ({
					color: theme.palette.neutral[700],
					backgroundColor: theme.palette.neutral[50],
				})}
				onClick={toggleBanner}
			>
				{t("receipts_info_modal.receipts_info_button")}
			</Button>
			<ReceiptInfoDialog
				open={isBannerOpen}
				closeModal={() => toggleBanner()}
			/>
		</Box>
	);
};

export default ReceiptInfoButton;
