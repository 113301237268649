export const APP_ROUTES = {
	TILLS: {
		/** @deprecated: Use APP_ROUTES.TILLS.CLAIM_LAST_RECEIPT */
		LEGACY_CLAIM_LAST_RECEIPT: (tillId: string) => `/caisse/${tillId}`,
		CLAIM_LAST_RECEIPT: (tillId: string) => `/tills/${tillId}`,
	},
	AUTH: {
		AUTHENTICATE: "/authenticate",
		FORGOT_PASSWORD: "/forgot-password",
		RESET_PASSWORD: "/reset-password",
		EXTERNAL_LOGIN_CALLBACK: "/login/callback",
	},
	ONBOARDING: "/onboarding",
	RECEIPTS: {
		LIST: "/",
		CLAIM_RECEIPT: (receiptId: string) => `/receipts/${receiptId}`,
		USER_RECEIPT: (receiptId: string) => `/user/receipts/${receiptId}`,
	},
	CGU: "https://noticia.ai/conditions-generales-dutilisation/",
	PRIVACY_POLICY: "/privacy-policy",
	GAMES: {
		SCRATCHING_GAME_RULES: "/scratching-games/rules",
	},
	NOT_FOUND: "/404",
};

export const API_ROUTES = {
	AUTH: {
		USER: "/v2/user",
		UPDATE_USER: "/v2/user",
		CHECK_EMAIL_AVAILABILITY: "/v2/auth/check-email",
		LOGIN: "/v2/auth/login",
		REGISTER: "/v2/auth/register",
		FORGOT_PASSWORD: (email: string) => `/v2/user/forgot-password/${email}`,
		RESET_PASSWORD: "/v2/user/reset-password",
		UPGRADE_JWT_FROM_V1: "/v2/auth/upgrade-from-v1",
		OAUTH2: {
			CONNECT_WITH_GOOGLE: "/v2/auth/google",
		},
	},
	RECEIPTS: {
		LIST: "/v2/user/receipts",
		ID: (receiptId: string) => `/v1/receipts/${receiptId}`,
		INSPECT: (receiptId: string, securityHash: string) =>
			`/v2/receipts/${receiptId}/inspect?securityHash=${securityHash}`,
		CLAIM: (receiptId: string) => `/v1/receipts/${receiptId}/claim`,
	},
	TILLS: {
		CLAIM_LAST_RECEIPT: (tillId: string) =>
			`/v1/tills/${tillId}/claim-last-receipt`,
	},
	EVENTS: {
		PROMOTION_CLICK: (pointOfSaleId: string, promotionId: string) =>
			`/v2/points-of-sale/${pointOfSaleId}/promotions/${promotionId}/click`,
		REDIRECTION_LINK_CLICK: (
			pointOfSaleId: string,
			redirectionLinkId: string,
		) =>
			`/v2/points-of-sale/${pointOfSaleId}/redirection-links/${redirectionLinkId}/click`,
		GOOGLE_REVIEW: (pointOfSaleId: string) =>
			`/v2/points-of-sale/${pointOfSaleId}/reviews`,
		USER_EMBASEMENT: (pointOfSaleId: string) =>
			`/v2/points-of-sale/${pointOfSaleId}/embasement`,
	},
	TRANSACTIONS: "/transactions",
	SCRATCHING_GAME_SESSIONS: {
		PLAY: (scratchingGameSessionId: string) =>
			`/v1/scratching-game-sessions/${scratchingGameSessionId}/play`,
		RETRIEVE_REWARD: (scratchingGameSessionId: string) =>
			`/v1/scratching-game-sessions/${scratchingGameSessionId}/claim-reward`,
	},
	SCRATCH_GAMES: {
		PLAY: (scratchGameId: string) => `/v2/scratch-games/${scratchGameId}/play`,
		COLLECT_PRIZE: (scratchGameId: string) =>
			`/v2/scratch-games/${scratchGameId}/collect-prize`,
	},
};
