import { createTheme, lighten } from "@mui/material/styles";
import type { ThemeOptions } from "@mui/material/styles/createTheme";
import deepmerge from "@mui/utils/deepmerge";

import { shadows } from "@noticia/ui";

const palette = {
	primary: {
		light: "#D5E1FE",
		main: "#495EFD",
		dark: "#232173",
		contrastText: "#ffffff",
	},
	white: {
		main: "#ffffff",
		contrastText: "#232173",
	},
	text: {
		primary: "#232173", // Same color as navyBlue.900
		secondary: "#BDBDBD",
		main: "#262626",
	},
	background: {
		default: "#FFFFFF",
	},
	success: {
		light: "#34A853",
		main: "#2E7D32",
		dark: "#1B5E20",
	},
	warning: {
		light: "#FFBC1D",
		main: "#FFBC1D",
		dark: "#FFBC1D",
	},
	navyBlue: {
		50: "#F5F6FF",
		100: "#E5E8FF",
		200: "#CDD2FE",
		300: "#9AA6FE",
		400: "#6879FD",
		500: "#495EFD",
		600: "#0320FC",
		700: "#0219CA",
		800: "#021397",
		900: "#010D65",
		950: "#161836",
	},
	neutral: {
		0: "#FFFFFF",
		25: "#F9FAFB",
		50: "#F3F4F6",
		100: "#EAECF0",
		200: "#E2E5EA",
		300: "#C4CAD4",
		400: "#A8B0BE",
		500: "#8A95A8",
		600: "#6C7A93",
		700: "#576275",
		800: "#414958",
		900: "#2B313B",
		950: "#21252C",
	},
	danger: {
		50: "#FAEAEB",
		100: "#F6D5D7",
		200: "#ECACAF",
		300: "#E38287",
		400: "#D9595F",
		500: "#D02F37",
		600: "#A6262C",
		700: "#7D1C21",
		800: "#531316",
		900: "#2A090B",
	},
	orange: {
		50: "#FFF7E5",
		100: "#FFF0CC",
		200: "#FFE199",
		300: "#FFD466",
		400: "#FFC333",
		500: "#FFB300",
		600: "#CC9000",
		700: "#996C00",
		800: "#664800",
		900: "#332400",
	},
	green: {
		50: "#EBF9F3",
		100: "#D8F3E8",
		200: "#B1E7D1",
		300: "#8ADBB9",
		400: "#63CFA2",
		500: "#3CC38B",
		600: "#309C6F",
		700: "#247553",
		800: "#184E37",
		900: "#0C271C",
	},
};

const typography = {
	fontFamily: [
		"DM Sans",
		"Comfortaa",
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	// https://type-scale.com/ 1.2000 minor third
	h1: {
		fontFamily: "DM Sans",
		fontSize: "48px",
		lineHeight: "52px",
		color: palette.navyBlue[950],
	},
	h2: {
		fontFamily: "DM Sans",
		fontSize: "34px",
		lineHeight: "38px",
		color: palette.navyBlue[950],
	},
	h3: {
		fontFamily: "DM Sans",
		fontSize: "32px",
		lineHeight: "36px",
		color: palette.navyBlue[950],
	},
	h4: {
		fontFamily: "DM Sans",
		fontSize: "24px",
		lineHeight: "32px",
		color: palette.navyBlue[950],
	},
	body1: {
		fontFamily: "DM Sans",
		fontSize: "20px",
		lineHeight: "24px",
	},
	body2: {
		fontFamily: "DM Sans",
		fontSize: "18px",
		lineHeight: "21px",
	},
	body3: {
		fontFamily: "DM Sans",
		fontSize: "16px",
		lineHeight: "21px",
	},
	body4: {
		fontFamily: "DM Sans",
		fontSize: "14px",
		lineHeight: "18px",
	},
	body5: {
		fontFamily: "DM Sans",
		fontSize: "12px",
		lineHeight: "16px",
	},
	caption1: {
		fontFamily: "DM Sans",
		fontSize: "10px",
		lineHeight: "15px",
	},
	caption2: {
		fontFamily: "DM Sans",
		fontSize: "8px",
		lineHeight: "12px",
	},
	button: {
		textTransform: "none" as const,
	},
};

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1440,
	},
};

const customShadows = [shadows.md, shadows.lg, ...Array(22).fill("")];

const theme = createTheme({
	// @ts-expect-error Target requires 25 element(s) but source may have fewer.
	shadows: ["none", ...(customShadows as string[])],
	palette,
	typography,
	breakpoints,
});

const components: ThemeOptions["components"] = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				height: "100vh",
			},
			body: {
				height: "100vh",

				"& > #root": {
					height: "100%",
				},
				"& button": {
					borderWidth: 0,
				},
			},
			a: {
				textDecoration: "none",
			},
		},
	},
	MuiCard: {
		defaultProps: {
			elevation: 1,
		},
		styleOverrides: {
			root: {
				borderRadius: theme.spacing(2.5),
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				"&:last-child": {
					paddingBottom: theme.spacing(2),
				},
			},
		},
	},
	MuiCardActions: {
		styleOverrides: {
			root: {
				paddingBottom: theme.spacing(2),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},
		},
	},
	// Shared across all button types (Button, IconButton, ...)
	MuiButtonBase: {
		styleOverrides: {
			root: {
				"&:disabled": {
					cursor: "not-allowed",
					pointerEvents: "initial",
				},
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: {
				color: theme.palette.primary.main,
				fontWeight: 700,

				paddingLeft: theme.spacing(6),
				paddingRight: theme.spacing(6),
				borderTopLeftRadius: theme.spacing(1),
				borderTopRightRadius: theme.spacing(1),

				"&.Mui-selected": {
					color: theme.palette.navyBlue[900],
					backgroundColor: theme.palette.navyBlue[200],
				},

				// Without the :not selector, it will apply to the selected tab on hover
				"&:hover:not(.Mui-selected)": {
					backgroundColor: lighten(theme.palette.navyBlue[200], 0.5),
					transition: "0.3s",
				},
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				padding: "0 2px", //top-bottom left-right
				fontSize: theme.typography.caption1.fontSize,
				borderWidth: 0,
			},
		},
	},
	MuiTextField: {
		defaultProps: {
			variant: "filled",
		},
	},
	MuiFilledInput: {
		defaultProps: {
			disableUnderline: true,
		},
		styleOverrides: {
			root: {
				color: theme.palette.neutral[950],
				backgroundColor: theme.palette.neutral[0],
				borderBottomLeftRadius: theme.spacing(1),
				borderBottomRightRadius: theme.spacing(1),
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				color: theme.palette.neutral[400],
				"&.Mui-focused": {
					color: theme.palette.neutral[400],
				},
			},
		},
	},
	MuiFormControl: {
		defaultProps: {
			variant: "filled",
		},
	},
	MuiTypography: {
		styleOverrides: {
			gutterBottom: {
				marginBottom: theme.spacing(4),
			},
		},
		defaultProps: {
			variantMapping: {
				h1: "h1",
				h2: "h2",
				h3: "h3",
				h4: "h4",

				body1: "p",
				body2: "p",
				body3: "p",
				body4: "p",
				body5: "p",

				caption1: "span",
				caption2: "span",
			},
		},
	},
	MuiToolbar: {
		styleOverrides: {
			root: {
				minHeight: "unset",
				padding: theme.spacing(2.5),
				color: theme.palette.navyBlue[900],
			},
		},
	},
	MuiStepLabel: {
		styleOverrides: {
			iconContainer: {
				paddingRight: theme.spacing(2),
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			rounded: {
				borderRadius: 8,
			},
		},
	},
	MuiLink: {
		styleOverrides: {
			root: {
				cursor: "pointer",
			},
		},
	},
	MuiAlert: {
		styleOverrides: {
			root: {
				fontSize: theme.typography.body3.fontSize,
				padding: theme.spacing(2, 3),
				borderRadius: theme.spacing(1),
			},
			standardInfo: {
				color: theme.palette.neutral[0],
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
	MuiAlertTitle: {
		styleOverrides: {
			root: {
				fontSize: theme.typography.h4.fontSize,
			},
		},
	},
	MuiRating: {
		styleOverrides: {
			sizeLarge: {
				fontSize: "40px",
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			sizeMedium: {
				fontSize: theme.typography.body3.fontSize,
			},
		},
	},
	MuiDialog: {
		styleOverrides: {
			root: {
				"& > .MuiDialog-paper": {
					borderRadius: 99,
					margin: 0,
				},
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				borderBottomWidth: 1,
				borderBottomColor: theme.palette.neutral[300],
				borderBottomStyle: "solid",
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				borderTopWidth: 1,
				borderTopColor: theme.palette.neutral[300],
				borderTopStyle: "solid",
				justifyContent: "center",
			},
		},
	},
};

export default deepmerge(theme, {
	components,
});
