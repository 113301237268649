import type { PropsWithChildren, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownloadRounded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooksRounded";
import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MUILink from "@mui/material/Link";
import { Link } from "wouter";

import { Button, Typography } from "@noticia/ui";

import NavigationChip from "~/components/atoms/NavigationChip/NavigationChip";
import BottomDrawer from "~/components/molecules/BottomDrawer";
import { APP_ROUTES } from "~/constants";

type UserIntroductionDialogSectionProps = {
	title: ReactNode;
	icon: ReactNode;
};

const UserIntroductionDialogSection = (
	props: PropsWithChildren<UserIntroductionDialogSectionProps>,
) => {
	const { title, icon, children } = props;

	return (
		<Box display="flex">
			<Box
				p={0.5}
				mr={1}
				display="flex"
				sx={(theme) => ({
					backgroundColor: theme.palette.neutral[200],
					borderRadius: 10,
					height: "min-content",
				})}
			>
				{icon}
			</Box>
			<div>
				<Typography variant="body4" fontWeight="bold">
					{title}
				</Typography>
				<Typography
					variant="body4"
					fontWeight="regular"
					component="div"
					sx={(theme) => ({ color: theme.palette.text.main })}
				>
					{children}
				</Typography>
			</div>
		</Box>
	);
};

type Props = {
	open: boolean;
	closeModal: () => void;
};

const ReceiptInfoDialog = (props: Props) => {
	const { open, closeModal } = props;

	const { t } = useTranslation(["receipts", "common"]);

	return (
		<BottomDrawer
			title={t("receipts_info_modal.receipts_info_title")}
			open={open}
			showCloseButton
			onClose={closeModal}
		>
			<Container maxWidth="sm" sx={{ pt: 0.25, px: 0 }}>
				<Box display="flex" flexDirection="column" justifyContent="center">
					<UserIntroductionDialogSection
						title={t("receipts_info_modal.receipts_info_section_1_title")}
						icon={<AccountCircleIcon sx={{ height: 14, width: 14 }} />}
					>
						<Trans
							ns="receipts"
							i18nKey="receipts_info_modal.receipts_info_section_1_content"
							components={{
								login_link: (
									<MUILink to={APP_ROUTES.AUTH.AUTHENTICATE} component={Link} />
								),
							}}
						/>
					</UserIntroductionDialogSection>
					<Box mt={2}>
						<UserIntroductionDialogSection
							title={t("receipts_info_modal.receipts_info_section_2_title")}
							icon={<Visibility sx={{ height: 14, width: 14 }} />}
						>
							<Trans
								ns="receipts"
								i18nKey="receipts_info_modal.receipts_info_section_2_content"
								components={{
									receipts_link: (
										<Box
											sx={{
												ml: 0.25,
												display: "inline-block",
												verticalAlign: "middle",
											}}
										>
											<NavigationChip
												label={t("my_receipts")}
												href={APP_ROUTES.RECEIPTS.LIST}
												size="small"
												icon={
													<LibraryBooksIcon
														sx={{ mr: 0.5, ml: 1, height: 12, width: 12 }}
													/>
												}
											/>
										</Box>
									),
									noticia_link: (
										<MUILink href="https://www.noticia.ai" target="_blank" />
									),
								}}
							/>
						</UserIntroductionDialogSection>
					</Box>
					<Box my={1}>
						<Divider
							sx={(theme) => ({ ...theme.typography.body3, fontWeight: 400 })}
						>
							{t("common:or")}
						</Divider>
					</Box>
					<UserIntroductionDialogSection
						title={t("receipts_info_modal.receipts_info_section_3_title")}
						icon={<FileDownloadIcon sx={{ height: 14, width: 14 }} />}
					>
						<Trans
							ns="receipts"
							i18nKey="receipts_info_modal.receipts_info_section_3_content"
							components={{
								download_icon: (
									<Box
										sx={{
											mt: 0.5,
											display: "inline-block",
											verticalAlign: "middle",
										}}
									>
										<FileDownloadIcon sx={{ height: 14, width: 14 }} />
									</Box>
								),
							}}
						/>
					</UserIntroductionDialogSection>
					<Button
						variant="primary"
						color="dark_blue"
						size="large"
						rounded
						onClick={closeModal}
						sx={{
							mt: 4,
							minWidth: 250,
							width: { xs: "100%", sm: "min-content" },
							mx: "auto",
						}}
					>
						{t("common:understood")}
					</Button>
				</Box>
			</Container>
		</BottomDrawer>
	);
};

export default ReceiptInfoDialog;
