import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Link } from "wouter";

import { Typography } from "@noticia/ui";

import ChangeLanguage from "~/components/molecules/ChangeLanguage";
import { APP_ROUTES } from "~/constants";

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Stack
			pt={2}
			pb={3}
			spacing={2}
			divider={<span>|</span>}
			direction="row"
			alignItems="center"
			justifyContent="center"
		>
			<ChangeLanguage />

			<Link to={APP_ROUTES.PRIVACY_POLICY}>
				<Typography variant="body5" fontWeight="regular" textAlign="center">
					{t("privacy_policy")}
				</Typography>
			</Link>

			<a
				href="https://noticia.ai/conditions-generales-dutilisation/"
				target="_blank"
				rel="noreferrer"
			>
				<Typography variant="body5" fontWeight="regular" textAlign="center">
					{t("terms_of_use")}
				</Typography>
			</a>
		</Stack>
	);
};

export default Footer;
