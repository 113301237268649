import type { ReactNode } from "react";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import type { TFunction } from "i18next";

import { RedirectionLinkType, ScratchGameActionType } from "~/services/api";

export const interactionTypeToRedirectionLinkTypeMapper: Record<
	Exclude<ScratchGameActionType, "POS_REVIEW" | "EMBASEMENT">,
	Exclude<RedirectionLinkType, "other">
> = {
	[ScratchGameActionType.INSTAGRAM_LINK_CLICK]: RedirectionLinkType.INSTAGRAM,
	[ScratchGameActionType.FACEBOOK_LINK_CLICK]: RedirectionLinkType.FACEBOOK,
	[ScratchGameActionType.WEBSITE_LINK_CLICK]: RedirectionLinkType.WEB,
};

type RequiredUserActionPopupContent = {
	title: string;
	text: string;
	icon?: ReactNode;
	buttonText: string;
};

export const getRequiredUserActionPopupContent = (
	t: TFunction<"scratch_games">,
): Record<ScratchGameActionType, RequiredUserActionPopupContent> => ({
	POS_REVIEW: {
		title: t("required_actions.google_review.title"),
		text: t("required_actions.google_review.message"),
		buttonText: t("required_actions.google_review.buttonText"),
	},
	EMBASEMENT: {
		title: t("required_actions.embasement.title"),
		text: t("required_actions.embasement.message"),
		buttonText: t("required_actions.embasement.buttonText"),
	},
	INSTAGRAM_LINK_CLICK: {
		title: t("required_actions.redirectionLink_instagramFollow.title"),
		text: t("required_actions.redirectionLink_instagramFollow.message"),
		icon: (
			<img
				src="/icons/instagram_icon.svg"
				alt="Instagram logo"
				height={73}
				width={73}
			/>
		),
		buttonText: t(
			"required_actions.redirectionLink_instagramFollow.buttonText",
		),
	},
	FACEBOOK_LINK_CLICK: {
		title: t("required_actions.redirectionLink_facebookFollow.title"),
		text: t("required_actions.redirectionLink_facebookFollow.message"),
		icon: (
			<img
				src="/icons/facebook_icon.svg"
				alt="Facebook logo"
				height={73}
				width={73}
			/>
		),
		buttonText: t("required_actions.redirectionLink_facebookFollow.buttonText"),
	},
	WEBSITE_LINK_CLICK: {
		title: t("required_actions.redirectionLink_webVisit.title"),
		text: t("required_actions.redirectionLink_webVisit.message"),
		icon: <LanguageIcon sx={{ height: 90, width: 90 }} />,
		buttonText: t("required_actions.redirectionLink_webVisit.buttonText"),
	},
});
