import { datadogRum } from "@datadog/browser-rum";

function useTrackUser() {
	return function (userId?: string) {
		if (userId) {
			/** @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/#user-session */
			datadogRum.setUser({ id: userId });
		}
	};
}

export default useTrackUser;
