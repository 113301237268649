import { useCallback, useEffect } from "react";
import {
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from "@tanstack/react-query";
import { has, isEmpty, isNull } from "lodash";
import { useLocation } from "wouter";

import * as constants from "~/constants";
import { APP_ROUTES } from "~/constants";
import { userQueryKey } from "~/hooks/auth/userQueryKey";
import useTrackUser from "~/hooks/useTrackUser";
import { type ApiError, type User, UsersController } from "~/services/api";

type Options = Partial<UseQueryOptions<User, ApiError>> & {
	onSuccess?: (data: User) => void;
	onError?: () => void;
};

const useUser = (options: Options = {}) => {
	const { onSuccess, onError, enabled, ...restOptions } = options;

	const [location, setLocation] = useLocation();
	const queryClient = useQueryClient();
	const trackUser = useTrackUser();

	const { data, isLoading, isFetched, refetch, status } = useQuery<
		User,
		ApiError
	>({
		queryKey: userQueryKey.GET_USER,
		queryFn: () => UsersController.getAuthenticatedUser(),
		enabled:
			!isNull(localStorage.getItem(constants.localStorage.token)) && enabled,
		...restOptions,
	});

	const user = isEmpty(data) ? undefined : data;
	const isLogged = has(user, "email");

	const logout = useCallback(() => {
		localStorage.removeItem(constants.localStorage.token);
		localStorage.removeItem(constants.localStorage.reactQueryCache);
		queryClient.removeQueries();

		return location === APP_ROUTES.RECEIPTS.LIST
			? window.location.reload()
			: setLocation(APP_ROUTES.RECEIPTS.LIST);
	}, [queryClient, setLocation]);

	useEffect(() => {
		if (onError && status === "error") {
			onError();
		} else if (onSuccess && status === "success") {
			onSuccess(data);
			trackUser(data.id);
		}
	}, [status, data]);

	return {
		user,
		isLogged,
		isLoading,
		isFetched,
		logout,
		refetchUser: refetch,
	};
};

export default useUser;
