import { useEffect } from "react";
import { useCookie } from "react-use";
import { useQuery } from "@tanstack/react-query";
import { isNull, isString } from "lodash";
import { useLocation } from "wouter";

import * as constants from "~/constants";
import {
	type ApiError,
	AuthController,
	type UpgradeFromJwtV1Response,
} from "~/services/api";

const useUpgradeJwtFromV1 = () => {
	const [location, setLocation] = useLocation();

	const [jwtV1, , removeLegacyToken] = useCookie("token");

	const hasJwtV1 = isString(jwtV1);

	const { data, fetchStatus, status, error, ...request } = useQuery<
		UpgradeFromJwtV1Response | undefined,
		ApiError
	>({
		queryKey: ["migrate-jwt-from-v1"],
		queryFn: () => {
			if (isNull(jwtV1)) {
				throw new Error("User doesn't have JWT v1");
			}

			return AuthController.upgradeFromJwtV1();
		},
		enabled: hasJwtV1,
	});

	useEffect(() => {
		if (status === "success" && data) {
			removeLegacyToken();
			localStorage.setItem(constants.localStorage.token, data.token);

			setLocation(location);
		}

		if (status === "error" && error?.status === 404) {
			// The jwt v1 could have been decoded but no User with the id stored in it has been found
			removeLegacyToken();
		}
	}, [jwtV1, fetchStatus, data, error, removeLegacyToken]);

	return { fetchStatus, status, error, ...request };
};

export default useUpgradeJwtFromV1;
