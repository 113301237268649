import React from "react";
import ReactDOM from "react-dom/client";
import * as FullStory from "@fullstory/browser";
import { AxiosHeaders } from "axios";
import Cookies from "js-cookie";

import * as constants from "~/constants";
import App from "~/pages/App";
import { OpenAPI } from "~/services/api";

import "./i18n";

OpenAPI.BASE = import.meta.env.VITE_API_URL;

/** @see https://heyapi.vercel.app/openapi-ts/clients.html#interceptors */
OpenAPI.interceptors.request.use((request) => {
	const token = localStorage.getItem(constants.localStorage.token);
	const jwtV1 = Cookies.get("token");

	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	request.headers = new AxiosHeaders({
		...request.headers,
		// If the user has a v1 JWT, we must send it to the server to migrate it to a v2 JWT.
		// Otherwise, the app will be stuck because a v1 JWT is in the cookies but is never sent
		...(jwtV1 && { Authorization: `Bearer ${jwtV1}` }),
		...(token && { Authorization: `Bearer ${token}` }),
		"user-time-zone": timeZone,
	});

	return request;
});

if (["staging", "production"].includes(import.meta.env.VITE_ENV)) {
	import("@datadog/browser-logs").then(({ datadogLogs }) => {
		datadogLogs.init({
			env: import.meta.env.VITE_ENV,
			site: "datadoghq.eu",
			clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
			service: import.meta.env.VITE_DATADOG_SERVICE,
			forwardErrorsToLogs: true,
			forwardConsoleLogs: ["error"],
			sessionSampleRate: 100,
		});
	});

	import("@datadog/browser-rum").then(({ datadogRum }) => {
		datadogRum.init({
			env: import.meta.env.VITE_ENV,
			site: "datadoghq.eu",
			clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
			service: import.meta.env.VITE_DATADOG_SERVICE,
			applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 20,
			trackUserInteractions: false,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: "mask-user-input",
		});
	});
}

/**
 * Init users sessions recording with FullStory in production only
 * @see https://app.fullstory.com/ui/o-1MRVTP-na1
 */
FullStory.init({
	orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
	devMode: import.meta.env.VITE_ENV !== "production",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
