import Box from "@mui/material/Box";

import ReceiptSkeletonContent from "~/components/atoms/ReceiptSkeletonContent";
import ReceiptWrapper from "~/components/molecules/Receipts/ReceiptWrapper";
import { APP_CONTENT_WIDTH } from "~/constants";

const ReceiptSkeleton = () => {
	return (
		<Box mx="auto" width={APP_CONTENT_WIDTH}>
			<ReceiptWrapper>
				<ReceiptSkeletonContent />
			</ReceiptWrapper>
		</Box>
	);
};

export default ReceiptSkeleton;
