import type { PaymentType, QuantityCode } from "~/services/api";

export const paymentMethods: Record<PaymentType, string> = {
	card: "Payé par carte",
	cash: "Payé en espèces",
	cashReturn: "Rendu monnaie",
	loyalty: "Payé par points fidélité",
	voucher: "Payé par bon d'achat",
	other: "Payé par un autre moyen",
};

export const quantityCodeSymbols: Partial<Record<QuantityCode, string>> = {
	// No symbol for an article piece by piece (almost all articles)
	PCS: "",
	KGS: "kg",
	CMS: "cm",
	KME: "km",
};

export const RECEIPT_IDS = {
	DOWNLOAD_BUTTON: "download-button",
	INFO_BUTTON: "info-button",
	PRODUCTS_SECTION: "products-section",
	DISCOUNTS_LIST: "discount-list",
	RECEIPT_TOTAL: "receipt-total",
	PAYMENT_METHOD: "payment-method",
	PRICE_DECOMPOSITION: "price-decomposition",
	ITEM_DISCOUNT: "item-discount",
	PRODUCT_PRICE_DECOMPOSITION: "product-price-column",
	SUBTOTAL_RECEIPT_DIVIDER: "subtotal-receipt-divider",
	SUBTOTAL_SECTION: "subtotal-section",
	PRODUCT_ITEM_PRICE: "product-item-price",
	RECEIPT_TOTAL_PAID: "receipt-total-paid",
	PRODUCT_PRICE_QUANTITY: "product-price-quantity",
};
