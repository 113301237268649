import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const ReceiptSkeletonContent = () => (
	<Stack spacing={1.5} p={2}>
		{new Array(10).fill(0).map((_, index) => (
			<Skeleton
				key={`skeleton_${index}`}
				variant="rounded"
				width="100%"
				height={25}
			/>
		))}
	</Stack>
);

export default ReceiptSkeletonContent;
