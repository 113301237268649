import List, { ListProps } from "@mui/material/List";
import { styled } from "@mui/material/styles";

const PopOverList = styled(List)<ListProps>(({ theme }) => ({
	position: "absolute",
	zIndex: 2001,
	bottom: 12,
	left: -40,
	borderRadius: 16,
	backgroundColor: theme.palette.neutral[0],
	display: "flex",
	flexDirection: "column",
	width: "max-content",
	gap: theme.spacing(0.5),
	padding: theme.spacing(1),
}));

export default PopOverList;
