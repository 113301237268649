import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import Box, { BoxProps } from "@mui/material/Box";

import WidgetContainer from "~/components/atoms/WidgetContainer";
import ReceiptInfoButton from "~/components/organisms/ReceiptInfo/ReceiptInfoButton";

const ReceiptWrapper = forwardRef(
	(props: PropsWithChildren<BoxProps>, ref: ForwardedRef<HTMLDivElement>) => {
		const { children, ...boxProps } = props;

		return (
			<Box ref={ref} pt={2} pb={2.5} width="100%" {...boxProps}>
				<WidgetContainer pt={2}>
					<ReceiptInfoButton />
					{children}
				</WidgetContainer>
			</Box>
		);
	},
);

export default ReceiptWrapper;
