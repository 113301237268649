import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import Box, { BoxProps } from "@mui/material/Box";

import NavigationChip from "~/components/atoms/NavigationChip/NavigationChip";
import { APP_CONTENT_WIDTH, APP_ROUTES } from "~/constants";
import useTrackEvent from "~/hooks/useTrackEvent";

const BackToReceiptsNavigationChip = ({ sx }: Pick<BoxProps, "sx">) => {
	const { t } = useTranslation("receipts");
	const trackEvent = useTrackEvent();

	return (
		<Box
			mx="auto"
			width={APP_CONTENT_WIDTH}
			display="flex"
			sx={sx}
			onClick={() => trackEvent("click:my_receipts")}
		>
			<NavigationChip
				label={t("my_receipts")}
				href={APP_ROUTES.RECEIPTS.LIST}
				icon={<KeyboardArrowLeftIcon sx={{ ml: 0.5, height: 22, width: 22 }} />}
			/>
		</Box>
	);
};

export default BackToReceiptsNavigationChip;
