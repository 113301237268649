import { datadogRum } from "@datadog/browser-rum";

/**
 * Custom hook to log user actions for analytics and reporting purposes
 */
function useTrackEvent() {
	return function (event: string, context: Record<string, any> = {}) {
		/** @see https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/ */
		datadogRum.addAction(event, context);
	};
}

export default useTrackEvent;
