import { Link } from "wouter";

import Chip, { ChipProps } from "~/components/atoms/NavigationChip/Chip";

type Props = Omit<ChipProps, "onClick"> & {
	href: string;
};

const NavigationChip = (props: Props) => {
	const { href, ...chipProps } = props;

	return (
		<Link to={href}>
			<Chip {...chipProps} />
		</Link>
	);
};

export default NavigationChip;
